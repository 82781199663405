.CurrencySwitch {
  border-radius: 100px;
  background-color: var(--bg-color-gray);
  width: 100px;
  height: 40px;
  position: absolute;
  right: 0;
  padding: 5px;
}

.CurrencySwitch .switch-container {
  border-radius: 100px;
  width: 100%;
  height: 100%;
  position: relative;
}

.CurrencySwitch .switch {
  border-radius: 100px;
  background-color: var(--main-bg-color);
  color: var(--color-blue);
  height: 100%;
  width: 40px;
  aspect-ratio: 1;
  transition: all 500ms ease-in-out 0s;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.CurrencySwitch .heading {
  line-height: 1;
  transition: all 500ms ease-in-out 0s;
}

.CurrencySwitch .USD {
  position: absolute;
  right: 6px;
  top: 9px;
  line-height: 1;
}
.CurrencySwitch .NZD {
  position: absolute;
  left: 6px;
  top: 9px;
  line-height: 1;
}
