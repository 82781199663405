.CoinChart {
  margin: 0 15px;
  margin-bottom: 15px;
  padding: 30px;
  border-radius: 30px;
  background-color: var(--main-bg-color);
  position: absolute;
}

.line-graph {
  aspect-ratio: 2/1;
  width: 100%;
  height: auto;
  color: var(--main-color);
}

.chart-button {
  background-color: var(--bg-color-gray);
  width: -moz-fit-content;
  width: fit-content;
  padding: 12.5px 17.5px;
  border-radius: 100px;
  border: none;
  line-height: 1;
  cursor: pointer;
  margin: 5px;
  margin-bottom: 0px;
  transition: all 500ms ease-in-out 0s;
}

.chart-buttons-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#selected {
  background-color: var(--color-blue);
  color: var(--third-color);
}
