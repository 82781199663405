.ThemeSwitch {
  border-radius: 100px;
  background-color: var(--bg-color-gray);
  width: 80px;
  height: 40px;
  position: absolute;
  right: 0;
  padding: 5px;
}

.ThemeSwitch .switch-container {
  border-radius: 100px;
  width: 100%;
  height: 100%;
}

.ThemeSwitch .switch {
  border-radius: 100px;
  background-color: var(--main-bg-color);
  height: 100%;
  aspect-ratio: 1;
  transition: all 500ms ease-in-out 0s;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ThemeSwitch #switch-svg {
  margin: 0;
  fill: var(--color-blue);
  height: 16px;
  width: 16px;
  transition: all 500ms ease-in-out 0s;
}
