.HomePage .page-content {
  padding: 15px;
  padding-top: 10px;
  overflow: hidden;
  width: 100%;
  position: relative;
  min-height: 100vh;
}

.HomePage .top-trending {
  position: relative;
  width: 100%;
  height: 351.58px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.HomePage .top-trending-carousel {
  display: flex;
  position: absolute;
  transition: all 500ms ease-in-out 0s;
}

.HomePage .trending-card {
  padding: 15px;
  background-color: var(--main-bg-color);
  min-width: 250px;
  height: fit-content;
  border-radius: 30px;
  box-shadow: var(--box-shadow-color) 0px 6px 24px 0px;
  animation: fadeIn 0.75s ease forwards;
  cursor: pointer;
}

.HomePage .trending-card:first-child {
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.HomePage .trending-card:not(:nth-child(1)) {
  margin: 5px;
}

.HomePage .card-image-container {
  width: 100%;
  aspect-ratio: 1;
  background-color: var(--third-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  position: relative;
}

.card-rank-label {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--main-bg-color);
  padding: 10px 20px;
  border-radius: 30px;
}

.HomePage .card-image {
  width: 100px;
  height: 100px;
}

.HomePage .card-content {
  margin-top: 10px;
  margin-bottom: 10px;
}

.card-content-flex {
  display: flex;
  justify-content: space-between;
}

.HomePage .card-content p {
  padding-bottom: 0.75rem;
  padding-top: 0.25rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
