.CoinsTable {
  background-color: var(--main-bg-color);
  padding: 30px;
  padding-top: 0;
  position: relative;
  max-width: 1200px;
  border-radius: 30px;
  margin: 0 auto;
  box-shadow: var(--box-shadow-color) 0px 6px 24px 0px;
}

.CoinsTable .table-container {
  max-height: 550px;
  overflow-y: scroll;
  position: relative;
  -webkit-mask-image: linear-gradient(to bottom, black 85%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 85%, transparent 100%);
}

.table-container::-webkit-scrollbar {
  display: none;
}

.CoinsTable .coins-table {
  width: 100%;
  margin: auto;
  height: 200px;
  position: relative;
}

.table-row {
  padding-top: 10px;
  padding-bottom: 50px;
  cursor: pointer;
}

.table-head {
  position: relative;
  background-color: var(--main-bg-color);
}

.table-head .stickyTop {
  position: sticky;
  top: 0px;
  background-color: var(--main-bg-color);
  border-bottom: 2px solid rgb(237, 238, 245, 0.5);
}

.table-heading--right {
  text-align: right;
  padding-top: 10px;
  padding-bottom: 10px;
}

.table-heading--left {
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
}

.table-heading--center {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.coin-name-image {
  display: flex;
  align-items: center;
}

.coin-name-image_bottom-content {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.coin-rank {
  margin-right: 10px;
  background-color: var(--bg-color-gray);
  color: var(--main-color);
  padding: 2.5px 5px;
  border-radius: 5px;
  width: fit-content;
}

.coin-name-image img {
  margin-right: 10px;
}

.pagination-container {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
}

.pagination {
  padding: 10px;
  height: 52px;
  display: "flex";
  justify-content: "center";
  background-color: var(--secondary-bg-color);
  border-radius: 100px;
}

.pagination button {
  color: var(--main-color);
}

.hidden {
  visibility: hidden;
}

.search-container {
  padding: 10px 20px;
  background-color: var(--bg-color-gray);
  height: 52px;
  width: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  cursor: pointer;
  transition: all 500ms ease-in-out 0s;
}

.search-onclick {
  left: 0;
  width: 52px;
  height: 52px;
  border-radius: 100px;
  position: absolute;
  background-color: transparent;
}

.search-container_content {
  width: 100%;
  display: flex;
  transition: all 500ms ease-in-out 0s;
}

.search-icon-container {
  margin-left: -2px;
  margin-right: 20px;
}

.table-search-icon {
  height: 17px;
  width: 17px;
}

.CoinsTable_search-input {
  border: none;
}

@media only screen and (max-width: 768px) {
  .pagination-container li {
    width: 35px;
  }
  .pagination-container {
    flex-direction: column;
  }
  .search-container {
    margin-bottom: 10px;
  }
  .CoinsTable .table-container {
    -webkit-mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
  }
}
